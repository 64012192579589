import { COLORS } from '@global/styles/colors';
import { TABLET_768 } from '@global/styles/sizes';
import styled from 'styled-components';

export const TitleContainer = styled.h1`
  margin-bottom: 20px;
  border-bottom: 1px solid #d5d5d5;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #000000;

  p {
    margin: 0;
  }
  @media (max-width: ${TABLET_768}px) {
    font-size: 34px;
    line-height: 38px;
  }
`;

export const SubtitleContainer = styled.div`
  margin: 26px 0;
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #525252;

  @media (max-width: ${TABLET_768}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Author = styled.a`
  margin: 0;
  text-decoration: none;
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #0f92b1;

  &:hover {
    text-decoration: underline;
  }
`;

export const SeparateLine = styled.div`
  margin: 0 8px;
  font-size: 16px;
  line-height: 23px;
  color: #525252;
`;

export const MaterialInfo = styled.div`
  margin: 20px 0 23px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PublishedAt = styled.p`
  margin: 0;
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #525252;
`;

export const MaterialInfoWrapper = styled.div`
  display: flex;
`;

export const Share = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: relative;

  &:hover {
    svg {
      circle {
        fill: #0e8cb9;
      }
    }
  }
`;

export const Tag = styled.a`
  min-width: 103px;
  height: 29px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0792b1;
  text-decoration: none;
  background-color: #e5f4f7;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.bondiBlue};
    color: ${COLORS.white};
  }
`;

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 11px;
`;

export const ShareTooltip = styled.div<{ renderTooltip: boolean }>`
  width: max-content;
  padding: 10px;
  bottom: 90%;
  right: -100%;
  visibility: ${({ renderTooltip }) => (renderTooltip ? 'visible' : 'hidden')};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0792b1;
  text-decoration: none;
  background-color: #e5f4f7;
`;
