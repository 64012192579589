import { DESKTOP_1200, TABLET_768 } from '@global/styles/sizes';
import styled from 'styled-components';

export const CollectionContainer = styled.div`
  margin-top: 39px;
  display: flex;
  flex-direction: row;
  gap: 26px;

  @media (max-width: ${DESKTOP_1200}px) {
    flex-direction: column;
    margin: 39px 0 0;
  }
`;
export const RelatedPostContainer = styled.div`
  margin-top: 90px;
  padding-bottom: 90px;
  background-color: #fafafa;

  @media (max-width: ${DESKTOP_1200}px) {
    margin-top: 60px;
    padding-bottom: 40px;
  }

  @media (max-width: ${TABLET_768}px) {
    padding-bottom: 60px;
  }
`;

export const CollectionBodyContainer = styled.div`
  width: 74%;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
  }
`;

export const QuickAccessContainer = styled.div`
  width: 291px;
  display: flex;
  flex-direction: column;
  gap: 34px;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
  }
`;
export const InActionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 50px 0 70px;
  display: flex;
  flex-direction: row;
  gap: 27px;

  @media (max-width: ${DESKTOP_1200}px) {
    flex-direction: column-reverse;
  }
`;

export const CollectionIndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding-bottom: 90px;

  @media (max-width: ${DESKTOP_1200}px) {
    padding-bottom: 42px;
  }
  @media (max-width: ${TABLET_768}px) {
    padding-bottom: 40px;
  }
`;

export const CollectionBackHomepage = styled.a`
  margin-bottom: 10px;
  display: flex;
  font-family: 'Poppins';
  text-decoration: none;
  line-height: 25px;
  font-size: 16px;
  color: #00a8cf;

  &:hover {
    text-decoration: underline;
  }
`;
