import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import { ArticleType } from '@sections/ResourcesCenterHero/ResourcesCenterHero.types';
import { MostPopular as MostPopularType, MostPopularProps } from './mostPopular.types';
import {
  ArticleLink,
  ArticleTitle,
  AuthorData,
  BoxContainer,
  Slash,
  TitleContainer,
  WhiteSpace,
  Wrapper,
} from './mostPopular.styles';

const MostPopular = ({ mostPopular, articleType }: MostPopularProps) => {
  const handleArticleType = (article: MostPopularType) => {
    switch (articleType) {
      case ArticleType.Training:
        return {
          authorData: article.attributes.Schedule,
          authorDataSecondLine: article.attributes.Speaker,
          href: PATHS.TRAINING,
          textLink: 'Training',
        };
        break;

      case ArticleType.WebinarPost:
        return {
          authorData: article.attributes.Schedule,
          authorDataSecondLine: article.attributes.Speaker,
          href: PATHS.WEBINAR,
          textLink: 'Webinar',
        };
        break;
      // Article
      default:
        return {
          authorData: article.attributes.Author,
          authorDataSecondLine: article.attributes.Publication_Date,
          href: PATHS.MATERIAL,
          textLink: 'Blog',
        };
    }
  };
  return (
    <GlobalWrapper>
      <Wrapper>
        <TitleContainer>Most Popular</TitleContainer>
        {mostPopular.map((article) => {
          const articleData = handleArticleType(article);
          return (
            <BoxContainer>
              <AuthorData>
                {articleData.authorData} <Slash>|</Slash> <WhiteSpace />
                {articleData.authorDataSecondLine}
              </AuthorData>
              <ArticleTitle
                href={`${articleData.href}/${transformToSlug(
                  article.attributes.Title.toLowerCase(),
                )}`}
              >
                {article.attributes.Title}
              </ArticleTitle>
              <ArticleLink href={`${articleData.href}`}>{articleData.textLink}</ArticleLink>
            </BoxContainer>
          );
        })}
      </Wrapper>
    </GlobalWrapper>
  );
};

export default MostPopular;
