import React, { SVGProps } from 'react';

const ShareIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" {...props}>
      <circle data-name="Ellipse 253" cx="18" cy="18" r="18" fill="#252a34" />
      <path
        d="M22.432 23.526A4.433 4.433 0 0 1 18 27.958h-6.2a4.433 4.433 0 0 1-4.435-4.432v-6.2a4.433 4.433 0 0 1 4.432-4.439h.886a.886.886 0 0 1 0 1.773h-.886a2.667 2.667 0 0 0-2.659 2.659v6.2a2.667 2.667 0 0 0 2.659 2.668h6.2a2.667 2.667 0 0 0 2.659-2.659.886.886 0 1 1 1.773 0Zm5.433-11.619L23.07 6.952a.875.875 0 0 0-1.25-.018.883.883 0 0 0-.018 1.25l4.556 4.7h-7.471a4.433 4.433 0 0 0-4.432 4.429v4.432a.886.886 0 1 0 1.773 0v-4.432a2.667 2.667 0 0 1 2.659-2.659h7.463l-4.556 4.7a.882.882 0 0 0 .638 1.5.915.915 0 0 0 .638-.266l4.786-4.941a2.656 2.656 0 0 0 .009-3.749Z"
        fill="#fff"
      />
    </svg>
  );
};

export default ShareIcon;
