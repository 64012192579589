export const transformToSlug = (input: string) => {
  // slug
  const originalSlug = input === undefined ? '' : input.replace('homepage', '');

  // Remove special characters excluding '-'
  const cleaned = originalSlug.replace(/[^a-zA-Z0-9- ]/g, '');

  // Convert to lowercase and replace spaces with '-'
  const lowercased = cleaned.toLowerCase();
  const replacedSpaces = lowercased.replace(/\s+/g, '-');

  // Replace consecutive '-' with a single '-'
  const slug = replacedSpaces.replace(/-+/g, '-');

  return slug;
};
